import { HttpContextToken } from '@angular/common/http';

export class Constants {
  public static TOKEN_REQUIRED = new HttpContextToken(() => true);
  public static paginationSizes = [5, 10, 20];
  public static paginationSizeDefault = 10;
  public static GOOGLE_AUTH_PLAY_STORE_LINK =
    'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2';
  public static GOOGLE_AUTH_APP_STORE_LINK =
    'https://apps.apple.com/us/app/google-authenticator/id388497605';

  public static ACCESS_TOKEN = () => localStorage.getItem('accessToken');
}

export enum Permissions {
  SuperAdmin = 'SUPERADMIN',
  UserCustomerManagement = 'USERCUSTOMERMANAGEMENT',
  SERVICE = 'SERVICE',
  CATEGORY = 'CATEGORY',
  CURRENCY = 'CURRENCY',
  VoucherProviderManagement = 'VOUCHERPROVIDERMANAGEMEN',
  VoucherBuyListManagement = 'VOUCHERBUYLISTMANAGEMENT',
  VoucherInventorManagement = 'VOUCHERINVENTORMANAGEMENT',
  ExchangeRate = 'EXCHANGERATE',
  UserBankAccount = 'USERBANKACCOUNT',
  Banks = 'BANKS',
  KycProvider = 'KYCPROVIDER',
  CustomerKyc = 'CUSTOMERKYC',
  FINANCE = 'FINANCE',
  TRANSACTION = 'TRANSACTION',
}
